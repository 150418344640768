<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/product/soft.png" alt="">
    </div>
    <div class="switchBar">
      <div class="tab cabinet" :class="{ active: switchIndex == 1 }" @click="switchClick(1)">智能维护平台</div>
      <div class="tab wireless" :class="{ active: switchIndex == 2 }" @click="switchClick(2)">中间件平台</div>
      <div class="tab gateway" :class="{ active: switchIndex == 3 }" @click="switchClick(3)">数字孪生</div>
      <!-- <div class="tab gateway" :class="{ active: switchIndex == 3 }" @click="switchClick(4)">无线网管平台软件系列</div> -->
      <router-link to="/productCenter" class="tab more">更多产品</router-link>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer platformContiner" v-if="switchIndex == 1">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              智能维护平台
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯网络管理平台是企业级的网络管理系统，采用单一的软件解决方案，<br>
              为网络管理员简化了大量设备管理和监控任务。多种网络都可使用一个服务<br>
              器实现监控和维护。丰富的设置特性帮助您有效诊断网络问题，可视化网络<br>
              地图，自动按计划执行固件升级，更新设备状态，获取失败的 警报信息，收<br>
              集统计数据等。基于 Web 系统环境，支持多用户账号管理。多个管理员可<br>
              以在同一个服务器上管理不同的网络，且不用直接访问各自网络的设备。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <img src="../../assets/product/platform.png" alt="">
        </div>
      </div>
      <div class="continer platformContiner" v-if="switchIndex == 2">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              中间件平台
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中间件平台是物联网软件产品，是介于AGV硬件系统与应用系统中间，<br>
              具有承上启下的作用，通过应用系统的操作将指令信息集成、逻辑处理，传<br>
              给AGV硬件系统进行设备操作，同时将设备系统状态，位置等信息进行逻辑<br>
              处理后并反馈给应用软件进行显示、处理，实现沟通交互。主要的目的是解<br>
              决设备系统接入、调度、系统融合互联互通的问题。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中间件平台具备更强的功能，不仅包含以上中间件平台的所有功能，而<br>
              且还兼有界面展示、配置中心等功能，提供统一的接口文档和数据处理服务<br>
              ，以及统一的上层应用接口，可以方便客户更快接入行业应用系统，可广泛<br>
              应用于智能工厂等领域。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <img src="../../assets/product/platform.png" alt="">
        </div>
      </div>
      <div class="continer platformContiner" v-if="switchIndex == 3">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              数字孪生
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数字孪生是“将带有三维数字模型的信息拓展到整个生命周期中的影像技<br>
              术，最终实现虚拟与物理数据同步和一致”，它可以“发现潜在问题、激发创<br>
              新思维、不断追求优化进步”。“数字孪生技术帮助企业在实际投入生产之前<br>
              即能在虚拟环境中优化、仿真和测试，在生产过程中也可同步优化整个企业流<br>
              程。”数字孪生是充分利用物理模型、传感器更新、运行历史等数据，集成多<br>
              学科、多物理量、多尺度、多概率的仿真过程，在虚拟空间中完成映射，从而<br>
              反映相对应的实体装备的全生命周期过程。数字孪生是一种超越现实的概念，<br>
              可以被视为一个或多个重要的、彼此依赖的装备系统的数字映射系统。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <img src="../../assets/product/platform.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Product',
  components: {
    topBarY,
    baseBar
  },
  data() {
    return {
      switchIndex: 1
    }
  },
  methods: {
    switchClick(index) {
      this.switchIndex = index
    }
  },
  created() {
    
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  width: 1920px;
}

.switchBar {
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 140px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 40px;
}

.switchBar .more {
  margin-right: 0;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

.switchContiner .weight{
  color: #333;
}

/* 智能保管柜 */
.switchContiner .cabinetContiner {
  margin: 0 auto;
  width: 1400px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .cabinetContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .cabinetContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .cabinetContiner .continer .txt {
  width: 477px;
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

/* 工业无线传输 */
.switchContiner .wirelessContiner {
  margin: 0 auto;
  width: 1400px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .wirelessContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .wirelessContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .wirelessContiner .continer .txt {
  width: 477px;
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

/* 智能网管控制 */
.switchContiner .gatewayContiner {
  margin: 0 auto;
  width: 1400px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .gatewayContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .gatewayContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .gatewayContiner .continer .txt {
  width: 477px;
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

/* 无线网管平台软件系列 */
.switchContiner .platformContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .platformContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .platformContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .platformContiner .continer .txt {
  width: 477px;
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .platformContiner img {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
}
</style>
